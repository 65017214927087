.typingIndicator {
  will-change: transform;
  position: relative;
  /* animation: 2s bulge infinite ease-out; */
}

.cursor {
  display: inline-block;
  width: 2ch;
  height: 2ch;
  animation: 2s blink 2s infinite;
  opacity: 0.4;
  margin-bottom: 4px;
  background-color: #c9c9c9;
  border-radius: 50%;
}

@keyframes flicker {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.typingIndicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #9e9ea1;
  border-radius: 50%;
  display: block;
  opacity: 0.4;
}

.typingIndicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}
.typingIndicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}
.typingIndicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}
@keyframes blink {
  50% {
    opacity: 1;
  }
}
@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}
